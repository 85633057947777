import React from 'react';
import withPreview from '../../utils/with-preview';

import Icon from '../../atoms/icon';
import './ScaricaApp.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';

const EMPTY_OBJECT = {};
const ScaricaApp = ({ data }) => {
  const moduleScaricaApp = data?.moduleScaricaApp || EMPTY_OBJECT;
  const icon = moduleScaricaApp?.content?.iconSvgId?.value || '';
  const iconThickness =
    moduleScaricaApp?.content?.iconSvgId?.content?.iconThickness?.value[0] ||
    '';
  const image = moduleScaricaApp?.content?.image?.node?.gatsbyImageData;
  const imageAlt = moduleScaricaApp?.content?.image?.value?.title;
  const title = moduleScaricaApp?.content?.title?.value;
  const description = moduleScaricaApp?.content?.description?.value;
  const appStoreImage =
    moduleScaricaApp?.content?.appStoreBadge?.node?.gatsbyImageData;
  const appStoreLink =
    moduleScaricaApp?.content?.appStoreBadge?.content?.appStoreLink?.value;
  const appStoreAlt = moduleScaricaApp?.content?.appStoreBadge?.value?.title;
  const playStoreImage =
    moduleScaricaApp?.content?.playStoreImage?.node?.gatsbyImageData;
  const playStoreLink =
    moduleScaricaApp?.content?.playStoreImage?.content?.playStoreLink?.value;
  const playStoreAlt = moduleScaricaApp?.content?.playStoreImage?.value?.title;
  const backgroundColor = moduleScaricaApp?.content?.backgroundColor?.value[0];
  return (
    <div
      className='scarica-app'
      data-module-name='scarica-app'
      data-module-code='C-63'
    >
      <div
        className='scarica-app__background'
        style={{
          '--bg': `var(--${backgroundColor})`,
        }}
      >
        <div className='scarica-app__content wrapper'>
          <div className='scarica-app__info'>
            {(image && (
              <GatsbyImage
                alt={imageAlt}
                objectFit='contain'
                className='scarica-app__logo--image scarica-app__logo'
                image={getImage(image)}
              />
            )) ||
              (icon && (
                <Icon
                  className='scarica-app__logo--icon scarica-app__logo'
                  icon={icon}
                  thickness={iconThickness}
                ></Icon>
              ))}
            <div className='scarica-app__label'>
              {title && <span className='scarica-app__title'>{title}</span>}
              {description && (
                <div className='box-editorial scarica-app__description'>
                  <ContenutoHtmlInner rawContent={description} />
                </div>
              )}
            </div>
          </div>
          <div className='scarica-app__links'>
            {appStoreImage && (
              <a
                target={'__blank'}
                href={appStoreLink}
                className='scarica-app__app-store'
              >
                <GatsbyImage
                  objectFit='contain'
                  className='scarica-app__link-image'
                  alt={appStoreAlt}
                  image={getImage(appStoreImage)}
                ></GatsbyImage>
              </a>
            )}
            {playStoreImage && (
              <a
                target={'__blank'}
                href={playStoreLink}
                className='scarica-app__play-store'
              >
                <GatsbyImage
                  objectFit='contain'
                  className='scarica-app__link-image'
                  alt={playStoreAlt}
                  image={getImage(playStoreImage)}
                ></GatsbyImage>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPreview(ScaricaApp);
